import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// 404 Error Component (AWS-like)
const NotFound = () => {
  return (
    <div style={styles.container}>
      {/* AWS logo placeholder */}
      <div style={styles.logo}>
        <span style={styles.awsText}>AWS</span>
        <span style={styles.awsService}>Error</span>
      </div>

      {/* Error Content */}
      <div style={styles.errorBox}>
        <h1 style={styles.errorCode}>404 - Page Not Found</h1>
        <p style={styles.errorMessage}>
          The page you are looking for might have been removed, had its name changed, 
          or is temporarily unavailable.
        </p>
        <hr style={styles.separator} />
        <p style={styles.suggestion}>
          Please contact your administrator for assistance or try refreshing the page.
        </p>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Root path (homepage) renders the NotFound error page */}
        <Route path="/" element={<NotFound />} />
        {/* Fallback for any undefined route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

// Styles
const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    backgroundColor: '#f9f9f9',
    color: '#333',
    textAlign: 'center',
    padding: '50px 20px',
    minHeight: '100vh',
  },
  logo: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  awsText: {
    color: '#FF9900', // AWS orange
    fontWeight: 'bold',
  },
  awsService: {
    color: '#333',
    marginLeft: '5px',
  },
  errorBox: {
    display: 'inline-block',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
  },
  errorCode: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#555',
  },
  errorMessage: {
    fontSize: '16px',
    marginBottom: '15px',
  },
  separator: {
    border: 'none',
    borderTop: '1px solid #eee',
    margin: '15px 0',
  },
  suggestion: {
    fontSize: '14px',
    color: '#666',
  },
};

export default App;
